import "soignant-nomade-ui/lib/assets/style.css";
import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { RemplacementFormContextProvider } from "./Contexts/RemplacementFormContext";

const Start = lazy(() => import("./Components/Screens/Start"));
const ScreenStatutPreuves = lazy(() => import("./Components/Screens/ScreenStatutPreuves"));
const ScreenCalendar = lazy(() => import("./Components/Screens/ScreenCalendar"));
const ScreenIdentite = lazy(() => import("./Components/Screens/ScreenIdentite"));
const ScreenCarteVitale = lazy(() => import("./Components/Screens/ScreenCarteVitale"));
const ScreenJustifDomicile = lazy(() => import("./Components/Screens/ScreenJustifDomicile"));
const ScreenDiplome = lazy(() => import("./Components/Screens/ScreenDiplome"));
const ScreenInscriptionOrdre = lazy(() => import("./Components/Screens/ScreenInscriptionOrdre"));
const ScreenHeuresTravailles = lazy(() => import("./Components/Screens/ScreenHeuresTravailles"));
const ScreenThanks = lazy(() => import("./Components/Screens/ScreenThanks"));
const ScreenRCP = lazy(() => import("./Components/Screens/ScreenRCP"))
const ScreenRIB = lazy(() => import("./Components/Screens/ScreenRIB"));

const App = () => {
  return (
    <RemplacementFormContextProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Start} />
          <Route path="/ScreenStatutPreuves" exact component={ScreenStatutPreuves} />
          <Route path="/ScreenCalendar" exact component={ScreenCalendar} />
          <Route path="/ScreenIdentite" exact component={ScreenIdentite} />
          <Route path="/ScreenCarteVitale" exact component={ScreenCarteVitale} />
          <Route path="/ScreenJustifDomicile" exact component={ScreenJustifDomicile} />
          <Route path="/ScreenDiplome" exact component={ScreenDiplome} />
          <Route path="/ScreenInscriptionOrdre" exact component={ScreenInscriptionOrdre} />
          <Route path="/ScreenHeuresTravailles" exact component={ScreenHeuresTravailles} />
          <Route path="/ScreenThanks" exact component={ScreenThanks} />
          <Route path="/ScreenRCP" exact component={ScreenRCP} />
          <Route path="/ScreenRIB" exact component={ScreenRIB} />
        </Switch>
      </Router>
    </RemplacementFormContextProvider>
  );
};

export default App;
