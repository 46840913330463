import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import Loader from './Components/Loader';
import LightLoader from './Components/LightLoader';
import reportWebVitals from './reportWebVitals';
import './assets/css/index.css'
import App from './App'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import { createUploadLink } from "apollo-upload-client";

const API_URL = process.env.REACT_APP_API_URL + "/query";


const uploadLinkWithUpload = createUploadLink({ uri: API_URL })

const client = new ApolloClient({
  link: uploadLinkWithUpload,
  cache: new InMemoryCache()
});

const g = "color:#00000;font-weight:bold;font-size:18px;";
const hello = `%c 👋 Hello, \n\n ✔️ Consulting. \n ✔️ Design. \n ✔️ Développement. \n 🤙 https://www.squirrel.fr`;
console.info(hello, g);

ReactDOM.render(
  <Suspense fallback={<LightLoader />}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
