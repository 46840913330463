import React from 'react';
import SvgLoader from "../assets/loader.svg";


function LightLoader() {
    return (
        <div className="light-loader" style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", zoom: "90%"}}>
            <img src={SvgLoader} alt="loader"/>
        </div>
    );
}

export default LightLoader;
