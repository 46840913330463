import { useState, createContext} from "react";
// import Joi from 'joi';

const initialState = {
    name: '',
    recordId: "null",
    CNIfile: null,
    CNIvalid: "false",
    dateDebut: null,
    dateDeNaissance: null,
    lieuDeNaissance: null,
    carteVitaleFile: null,
    carteVitaleValid: "false",
    carteVitaleNumber: null,
    justifDomicileFile: null,
    justifDomicileValid: "false",
    justifDomicileAdress: null,
    justifDomicileDate: null,
    diplomeFile: null,
    diplomeValid: "false",
    diplomeNumber: null,
    diplomeDate: null,
    inscriptionOrdreFile: null,
    inscriptionOrdreValid: "false",
    inscriptionOrdreNumber: null,
    inscriptionOrdrePassword: null,
    heuresTravailles: "Certificat de travail",
    heuresTravaillesFile: null,
    heuresTravaillesValid: "false",
    heuresTravaillesDate: null,
    heuresTravaillesNumber: null,
    RIBfile: null,
    RIBnumber: null,
    RCPFile: null,
    RCPDate: null,
    isVisibleDate: true,
    isVisibleCNI: true,
    isVisibleCarteVitale: true,
    isVisibleJustifDomicile: true,
    isVisibleDiplome: true,
    isVisibleInscriptionOrdre: true,
    isVisibleHeuresTravailles: true,
    isVisibleThanks: true,

}

export const RemplacementFormContext = createContext(initialState)

export const RemplacementFormContextProvider = ({ children }) => {
    const [name, setName] = useState(initialState.name)
    const [recordId, setRecordId] = useState(initialState.recordId)
    const [CNIfile, setCNIFile] = useState(initialState.CNIfile);
    const [CNIvalid, setCNIValid] = useState(initialState.CNIvalid);
    const [dateDebut, setDateDebut] = useState(initialState.dateDebut);
    const [dateDeNaissance, setDateDeNaissance] = useState(initialState.dateDeNaissance);
    const [lieuDeNaissance, setLieuDeNaissance] = useState(initialState.lieuDeNaissance);
    const [carteVitaleFile, setCarteVitaleFile] = useState(initialState.carteVitaleFile);
    const [carteVitaleValid, setCarteVitaleValid] = useState(initialState.CarteVitaleValid);
    const [carteVitaleNumber, setCarteVitaleNumber] = useState(initialState.carteVitaleNumber);
    const [justifDomicileFile, setJustifDomicileFile] = useState(initialState.justifDomicileFile);
    const [justifDomicileValid, setJustifDomicileValid] = useState(initialState.justifDomicileValid);
    const [justifDomicileAdress, setJustifDomicileAdress] = useState(initialState.justifDomicileAdress);
    const [justifDomicileDate, setJustifDomicileDate] = useState(initialState.justifDomicileDate);
    const [diplomeFile, setDiplomeFile] = useState(initialState.diplomeFile);
    const [diplomeValid, setDiplomeValid] = useState(initialState.diplomeValid);
    const [diplomeNumber, setDiplomeNumber] = useState(initialState.diplomeNumber);
    const [diplomeDate, setDiplomeDate] = useState(initialState.diplomeDate);
    const [inscriptionOrdreFile, setInscriptionOrdreFile] = useState(initialState.inscriptionOrdreFile);
    const [inscriptionOrdreValid, setInscriptionOrdreValid] = useState(initialState.inscriptionOrdreValid);
    const [inscriptionOrdreNumber, setInscriptionOrdreNumber] = useState(initialState.inscriptionOrdreNumber);
    const [inscriptionOrdrePassword, setInscriptionOrdrePassword] = useState(initialState.inscriptionOrdrePassword);
    const [heuresTravailles, setHeuresTravailles] = useState(initialState.heuresTravailles);
    const [heuresTravaillesValid, setHeuresTravaillesValid] = useState(initialState.heuresTravaillesValid);
    const [heuresTravaillesFile, setHeuresTravaillesFile] = useState(initialState.heuresTravaillesFile);
    const [heuresTravaillesDate, setHeuresTravaillesDate] = useState(initialState.heuresTravaillesDate);
    const [heuresTravaillesNumber, setHeuresTravaillesNumber] = useState(initialState.heuresTravaillesNumber);
    const [RIBfile, setRIBFile] = useState(initialState.RIBfile);
    const [RIBnumber, setRIBNumber] = useState(initialState.RIBnumber);
    const [RCPFile, setRCPFile] = useState(initialState.RCPFile);
    const [RCPDate, setRCPDate] = useState(initialState.RCPDate);
    const [isVisibleDate, setIsVisibleDate] = useState(initialState.isVisibleDate);
    const [isVisibleCNI, setIsVisibleCNI] = useState(initialState.isVisibleCNI);
    const [isVisibleCarteVitale, setIsVisibleCarteVitale] = useState(initialState.isVisibleCarteVitale);
    const [isVisibleJustifDomicile, setIsVisibleJustifDomicile] = useState(initialState.isVisibleJustifDomicile);
    const [isVisibleDiplome, setIsVisibleDiplome] = useState(initialState.isVisibleDiplome);
    const [isVisibleInscriptionOrdre, setIsVisibleInscriptionOrdre] = useState(initialState.isVisibleInscriptionOrdre);
    const [isVisibleHeuresTravailles, setIsVisibleHeuresTravailles] = useState(initialState.isVisibleHeuresTravailles);
    const [isVisibleThanks, setIsVisibleThanks] = useState(initialState.isVisibleThanks);

    return (
        <RemplacementFormContext.Provider value={{
            name, setName,
            recordId, setRecordId,
            CNIfile, setCNIFile,
            CNIvalid, setCNIValid,
            dateDebut, setDateDebut,
            dateDeNaissance, setDateDeNaissance,
            lieuDeNaissance, setLieuDeNaissance,
            carteVitaleFile, setCarteVitaleFile,
            carteVitaleValid, setCarteVitaleValid,
            carteVitaleNumber, setCarteVitaleNumber,
            justifDomicileFile, setJustifDomicileFile,
            justifDomicileValid, setJustifDomicileValid,
            justifDomicileAdress, setJustifDomicileAdress,
            justifDomicileDate, setJustifDomicileDate,
            diplomeFile, setDiplomeFile,
            diplomeValid, setDiplomeValid,
            diplomeNumber, setDiplomeNumber,
            diplomeDate, setDiplomeDate,
            inscriptionOrdreFile, setInscriptionOrdreFile,
            inscriptionOrdreValid, setInscriptionOrdreValid,
            inscriptionOrdreNumber, setInscriptionOrdreNumber,
            inscriptionOrdrePassword, setInscriptionOrdrePassword,
            heuresTravailles, setHeuresTravailles,
            heuresTravaillesFile, setHeuresTravaillesFile,
            heuresTravaillesValid, setHeuresTravaillesValid,
            heuresTravaillesDate, setHeuresTravaillesDate,
            heuresTravaillesNumber, setHeuresTravaillesNumber,
            RIBfile, setRIBFile,
            RIBnumber, setRIBNumber,
            RCPFile, setRCPFile,
            RCPDate, setRCPDate,
            isVisibleDate, setIsVisibleDate,
            isVisibleCNI, setIsVisibleCNI,
            isVisibleCarteVitale, setIsVisibleCarteVitale,
            isVisibleJustifDomicile, setIsVisibleJustifDomicile,
            isVisibleDiplome, setIsVisibleDiplome,
            isVisibleInscriptionOrdre, setIsVisibleInscriptionOrdre,
            isVisibleHeuresTravailles, setIsVisibleHeuresTravailles,
            isVisibleThanks, setIsVisibleThanks

        }}>
            {children}
        </RemplacementFormContext.Provider>
    );
}

export default RemplacementFormContext